/* :root {
  --colour-1: #65d8af;
  --colour-2: #e8128c;
  --colour-3: #f8d545;
  --colour-4: #913abd;
} */

div#root {
  min-height: 100vh;
  height: fit-content;
}

footer {
  position: sticky;
  top: 100vh;
  max-width: 100vw;
}

.clickable {
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Passion+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
